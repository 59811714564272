import { Button } from "antd";
import React from "react";
import "../../styles/home.css";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
const HomeContectUs = () => {
  return (
    <div className="home-contect-us-container">
      <div className="home-contect-us-heading-container">
        <p>
          <FormattedMessage id="best_place" />
        </p>
      </div>
      <div className="home-contect-us-paragraph-container">
        <p>
          <FormattedMessage id="best_place_text" />
        </p>
      </div>
      <Link to="/contact-us">
        <div className="home-contect-us-button-container">
          <Button>
            <FormattedMessage id="contact_us" />
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default HomeContectUs;
