import React from "react";
import "../../styles/home.css";
export const TeamMemberCard = ({ name, image }) => {
  return (
    <div>
      <div className="single-team-image-container">
        <div className="team-image-container">
          <img
            alt="AgentImage"
            src={`${image}`}
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div className="team-designation-container">
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};
