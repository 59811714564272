import { Axios } from "../../utils/axiosMiddleware";

export const fetchLocation = () => {
  return Axios.get("property/location");
};

export const fetchPriceRange = () => {
  return Axios.get("property/price");
};

export const fetchPropertyType = () => {
  return Axios.get("property/type");
};

export const fetchTeamMember = () => {
  return Axios.get("teams");
};
