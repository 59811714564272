/** @format */

import React from "react";
import "../../styles/home.css";
import { TeamMemberCard } from "../Team/TeamMemberCard";
import { Col, Row } from "antd";
import { useQuery } from "@tanstack/react-query";
import { fetchTeamMember } from "../../api/home/homeApi";
import { FormattedMessage } from "react-intl";
const Team = () => {
  const { data: teamData } = useQuery({
    queryKey: ["Team"],
    queryFn: () => fetchTeamMember(),
    select: (res) => res?.data?.records,
  });

  return (
    <>
      {teamData && (
        <div className="team-container" id="agents">
          <div className="team-heading-container">
            <p>
              <FormattedMessage id="our_team" />
            </p>
          </div>
          <div className="team-heading-container-2">
            <p>
              <FormattedMessage id="meet_our_team" />
            </p>
          </div>
          <div className="team-heading-container-3">
            <p>
              <FormattedMessage id="our_goal" />
            </p>
          </div>

          <Row justify={"center"}>
            {teamData?.map((item) => {
              return (
                <Col xs={24} md={12} lg={6} xxl={5} key={item?.name}>
                  <TeamMemberCard name={item?.name} image={item?.imageKey} />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </>
  );
};

export default Team;
