import React from "react";
import Header from "../Header";
import { FormattedMessage } from "react-intl";
import { BlogListing } from "./blog-listing";

export const Blog = () => {
  return (
    <div>
      <Header />
      <div className="blog-section">
        <h1 className="blog-page-top">
          <FormattedMessage id="Blogs" />
        </h1>
        <p className="blog-top-subheading">
          <FormattedMessage id="stay_ahead" />
        </p>
      </div>
      <BlogListing />
    </div>
  );
};
