import { Tag, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

export const columns = () => {
  const tableColumns = [
    {
      key: 1,
      title: (
        <div className="table_heading">
          <FormattedMessage id="No#" />
        </div>
      ),
      responsive: ["xs", "sm", "md"],
      ellipsis: true,
      align: "center",
      render: (_, record, index) => {
        return (
          <div style={{ fontFamily: "Nunito", fontSize: "18px" }}>
            {index + 1}
          </div>
        );
      },
    },
    {
      key: 1,
      title: (
        <div className="table_heading">
          <FormattedMessage id="Name" />
        </div>
      ),
      responsive: ["xs", "sm", "md"],
      ellipsis: true,
      align: "center",
      render: (_, record) => {
        return (
          <div style={{ fontFamily: "Nunito", fontSize: "18px" }}>
            {record?.name}
          </div>
        );
      },
    },
    {
      key: 0,
      title: (
        <div className="table_heading">
          <FormattedMessage id="Message" />
        </div>
      ),
      responsive: ["xs", "sm", "md"],
      ellipsis: true,
      align: "center",
      render: (_, record, index) => {
        return (
          <Tooltip title={record?.Property?.title_en}>
            <p className="card-state-description">{record?.description}</p>
          </Tooltip>
        );
      },
    },

    {
      title: (
        <div className="table_heading">
          <FormattedMessage id="Status" />
        </div>
      ),
      key: "action",
      align: "center",
      render: (_, record) => {
        return (
          <div>
            <Tag
              style={
                record?.status === "open"
                  ? {
                      backgroundColor: "green",
                      color: "white",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                    }
                  : record?.status === "close"
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                    }
                  : {
                      backgroundColor: "#f77e0b",
                      color: "white",
                      fontFamily: "Nunito",
                      fontSize: "16px",
                    }
              }
            >
              {record?.status === "open"
                ? "Open"
                : record?.status === "close"
                ? "Closed"
                : "Pending"}
            </Tag>
          </div>
        );
      },
    },
    {
      title: (
        <div className="table_heading">
          <FormattedMessage id="agent_number" />
        </div>
      ),
      key: "action",
      align: "center",
      render: (_, record) => {
        return (
          <div
            style={{
              fontFamily: "Nunito",
              fontSize: "16px",
            }}
          >
            {record?.assignedUser?.email}
          </div>
        );
      },
    },
  ];

  return tableColumns;
};
