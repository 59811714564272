import React from "react";
import { Card, Skeleton } from "antd";
export const SkeletonCard = () => {
  return (
    <Card className="card">
      <div className="property-card-image-text-container">
        <div className="property-card-image-text"></div>
        <Skeleton.Image active={false} className="card-image" />
      </div>
      <div style={{ padding: 10 }}>
        <Skeleton active={true} />
      </div>
    </Card>
  );
};
