import React from "react";
import "../../styles/privacyPolicy.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
const TermsCondition = () => {
  let navigate = useNavigate();
  return (
    <div>
      <div
        className="privacy-policy-button-container"
        onClick={() => navigate(-1)}
      >
        <div
          style={{
            display: "flex",
            width: "70px",
            paddingLeft: "130px",
            cursor: "pointer",
          }}
        >
          <ArrowLeftOutlined className="back-button-arrow" />
          <p style={{ margin: "auto" }} className="back-button-text">
            <FormattedMessage id="back" />
          </p>
        </div>
      </div>
      <div className="privacy-policy-heading-container">
        <p>
          <FormattedMessage id="t_and_s" />
        </p>
      </div>
      <div className="privacy-policy-paragraph-container">
        <p>
          <FormattedMessage id="last_update" />
        </p>
      </div>

      <div className="pricavy-policy-text-container">
        <ol>
          <li>
            <FormattedMessage id="welcome_intro" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="service_offered" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="user_registration" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="property_listing" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="property_search" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="property_valuation" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="property_marketing" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="intellectual_property" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="intellectual_property1" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="intellectual_property2" />
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsCondition;
