import { useQuery } from "@tanstack/react-query";
import { Col, Empty, Row, Table, Tabs } from "antd";
import React from "react";
import { getLikeProperty } from "../../api/Property/propertyApi";
import { FormattedMessage } from "react-intl";
import { PropertyCard } from "../Properties/PropertyCard";
import { columns } from "./column";
import { authAxios } from "../../utils/axiosMiddleware";

export const Dashboard = () => {
  const auth = JSON.parse(localStorage.getItem("userData"));

  const {
    data: favorite,
    isLoading: favoriteLoading,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  const { data: queries, isLoading: queryLoading } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["Queries"],
    queryFn: () => {
      return authAxios?.get(`tickets?userId=${auth?.id}`);
    },
    select: (res) => res?.data,
  });

  const TabsData = [
    {
      key: "1",
      label: (
        <h2 style={{ fontFamily: "Nunito" }}>
          <FormattedMessage id="user_liked_properties" />
        </h2>
      ),
      children: (
        <div style={{ marginTop: "70px" }}>
          <Row justify={"center"} style={{ width: "100%" }}>
            {favorite?.map((item) => {
              return (
                <Col
                  xs={24}
                  md={12}
                  xl={8}
                  xxl={7}
                  className="col-alignment"
                  key={item?.id}
                >
                  <PropertyCard
                    item={item?.Property}
                    liked={[{ id: item?.id }]}
                    refetch={refetch}
                  />
                </Col>
              );
            })}
          </Row>
          {favorite?.length === 0 && (
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <h2 style={{ fontFamily: "Nunito" }}>
          <FormattedMessage id="user_queries" />
        </h2>
      ),
      children: (
        <Table
          columns={columns()}
          dataSource={queries?.records}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: queries?.count,
          }}
          loading={!!queryLoading}
        />
      ),
    },
  ];

  return (
    <div style={{ paddingTop: "100px" }}>
      <Row
        style={{
          fontFamily: "Nunito",
          width: "100%",
          paddingLeft: "200px",
          paddingRight: "200px",
        }}
      >
        <Tabs
          style={{ width: "100%" }}
          defaultActiveKey="1"
          items={TabsData.map((item) => {
            return {
              key: item?.key,
              label: item?.label,
              children: item?.children,
              icon: item?.icon,
            };
          })}
        />
      </Row>
    </div>
  );
};
