import { configureStore } from "@reduxjs/toolkit";
import propertyReducer from "./reducers/propertyReducer";
import globalReducer from "./reducers/globalReducer";
import UserReducer from "./reducers/UserReducer";

export default configureStore({
  reducer: {
    property: propertyReducer,
    global: globalReducer,
    user: UserReducer,
  },
});
