import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Button, Col, Spin } from "antd";
import "../../styles/home.css";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPropertyList,
  getLikeProperty,
} from "../../api/Property/propertyApi";
import { PropertyCard } from "../Properties/PropertyCard";
import { LoadingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
const Property = () => {
  const navigate = useNavigate();
  const { data: propertyList, isLoading } = useQuery({
    queryKey: ["Property"],
    queryFn: () =>
      fetchPropertyList({ take: 6, skip: 0, minPrice: 0, maxPrice: 20000000 }),
    select: (res) => res?.data?.records,
  });

  const auth = JSON.parse(localStorage.getItem("userData"));

  const {
    data: favorite,
    isLoading: favoriteLoading,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  return (
    <div id="properties">
      <div className="property-heading-container">
        <p>
          <FormattedMessage id="our_properties" />
        </p>
      </div>
      <div className="property-paragraph-container">
        <p>
          <FormattedMessage id="our_feature_properties" />
        </p>
      </div>
      <div
        style={{
          width: "100%",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin
          spinning={!!isLoading || !!favoriteLoading}
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
                color: "#e5be54",
              }}
              spin
            />
          }
        />
      </div>
      <Row justify="center" align={"center"}>
        {!favoriteLoading &&
          propertyList?.map((item) => {
            const likeArr = favorite?.filter(
              (like) => like?.propertyId === item?.id
            );
            return (
              <Col
                xs={24}
                md={12}
                xl={8}
                xxl={7}
                className="col-alignment"
                key={item?.id}
              >
                <PropertyCard item={item} liked={likeArr} refetch={refetch} />
              </Col>
            );
          })}
      </Row>
      <div className="property-view-all-button-conatainer">
        <Button
          onClick={() =>
            navigate("/properties", {
              state: { type: "", location: "", price: "" },
            })
          }
        >
          <FormattedMessage id="view_all" />
        </Button>
      </div>
    </div>
  );
};

export default Property;
