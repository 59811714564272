import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Button,
  Divider,
  Carousel,
  Form,
  Avatar,
  Modal,
  Spin,
  message,
} from "antd";
import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import "../../styles/properties.css";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { base_url, bucket_base_url } from "../../constants/constant";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function PropertyDetails() {
  const navigate = useNavigate();
  const auth = JSON.parse(localStorage.getItem("userData"));

  const { state } = useLocation();
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#E5BE54",
      }}
      spin
    />
  );

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const param = useParams();
  const { id } = param;
  const [property, setProperty] = useState();
  const [spin, setSpin] = useState(false);
  const [agent, setAgent] = useState();

  const details = async () => {
    setSpin(true);
    try {
      const response = await axios.get(`${base_url}/property-listings/${id}`, {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      });
      setProperty(response?.data);
      setSpin(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getAgent = async (id) => {
    try {
      const response = await axios.get(`${base_url}/users/${id}`, {
        headers: {
          "ngrok-skip-browser-warning": true,
        },
      });
      setAgent(response?.data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (!property) {
      details();
    }
    if (property?.userId) {
      getAgent(property?.userId);
    }
  }, [property]);

  const propertyPhotos = property?.file;
  const [disable, setDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onFinish = async (values) => {
    setDisable(true);
    try {
      await axios.post(`${base_url}/tickets`, {
        name: String(values?.FullName),
        email: values?.email,
        subject: "interested",
        description: values?.message,
        propertyId: property?.id,
        ticketType: "property_visit",
        status: "open",
        phoneNumber: values?.phoneNumber,
        assignedUserId: agent?.id,
        userId: auth?.id,
      });
      setIsModalVisible(true);
      formRef.current.resetFields();
      setDisable(false);
    } catch (err) {
      message?.error(err?.res?.data?.message);
    }
  };
  return (
    <>
      {spin && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "110px",
          }}
        >
          <Spin spinning={spin} size="larger" indicator={antIcon} />
        </div>
      )}

      {property && (
        <>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="first_col">
                <div className="back-screen" onClick={() => navigate(-1)}>
                  <img src="/icons/leftarrowb.png" alt="" />
                  <span style={{ marginLeft: "5px" }}>
                    <FormattedMessage id="back" />
                  </span>
                </div>
                <Carousel
                  dots
                  style={{ width: "770px", height: "500px" }}
                  autoplay={true}
                >
                  {property?.file?.map((image, index) => {
                    return (
                      <div
                        style={{
                          marginTop: "22px",
                          width: "770px",
                          height: "500px",
                        }}
                        key={index}
                      >
                        <img
                          style={{ width: "770px", height: "500px" }}
                          src={
                            image?.filePath?.includes("http")
                              ? image?.filePath
                              : `${bucket_base_url}${image?.filePath}`
                          }
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Carousel>
                <div
                  style={{
                    width: "770px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "16px",
                  }}
                >
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[0]?.filePath?.includes("http")
                        ? propertyPhotos[0]?.filePath
                        : `${bucket_base_url}${propertyPhotos[0]?.filePath}`
                    }
                    alt=""
                  />
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[1]?.filePath?.includes("http")
                        ? propertyPhotos[1]?.filePath
                        : `${bucket_base_url}${propertyPhotos[1]?.filePath}`
                    }
                    alt=""
                  />
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[2]?.filePath?.includes("http")
                        ? propertyPhotos[2]?.filePath
                        : `${bucket_base_url}${propertyPhotos[2]?.filePath}`
                    }
                    alt=""
                  />
                  <img
                    className="lower-images"
                    src={
                      propertyPhotos[3]?.filePath?.includes("http")
                        ? propertyPhotos[3]?.filePath
                        : `${bucket_base_url}${propertyPhotos[3]?.filePath}`
                    }
                    alt=""
                  />
                </div>
                <div className="s-description">
                  <span className="font-style">{property?.title}</span>
                </div>
                <div
                  className="s-description"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex" }}>
                    <img src="/icons/bagicon.png" alt="" />
                    <span className="price-detail">
                      AED {`${property?.price}  ${property?.rental_period}`}
                    </span>
                  </div>
                  <div style={{ display: "flex" }}>
                    <img src="/icons/location.svg" alt="" />
                    <span className="location-detail">
                      {property?.community},{property?.city}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "770px",
                    marginTop: "20px",
                  }}
                >
                  <img src="/icons/homeicon.png" alt="" />
                  <span className="details-type">
                    {property?.property_type} for {property?.offering_type}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "770px",
                    marginTop: "20px",
                  }}
                >
                  <EyeOutlined style={{ fontSize: "20px" }} />
                  <span className="details-type">
                    <FormattedMessage id="Views" /> {property?.viewsCount}
                  </span>
                </div>

                <div className="s-description">
                  <div className="house-details">
                    <div className="box-div">
                      <img src="/icons/bedrect.png" alt="" />
                      <span className="box-text">
                        {property?.bedroom} Rooms
                      </span>
                    </div>
                    <Divider
                      type="vertical"
                      style={{ width: "2px", color: "black" }}
                    />
                    <div className="box-div">
                      <img src="/icons/bathrect.png" alt="" />
                      <span className="box-text">
                        {property?.bathroom} baths
                      </span>
                    </div>
                    <Divider
                      type="vertical"
                      style={{ width: "2px", color: "black" }}
                    />

                    <div className="box-div">
                      <img src="/icons/space.png" alt="" />
                      <span className="box-text">{`${property?.size} SQFT`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <div className="contact-box">
                  <span className="heading-contact">
                    <FormattedMessage id="contact_us" />
                  </span>
                  <Form
                    initialValues={{
                      FullName: state?.profileName,
                      email: state?.email,
                    }}
                    onFinish={(e) => onFinish(e)}
                    form={form}
                    ref={formRef}
                  >
                    <Form.Item
                      name="FullName"
                      rules={[
                        {
                          required: true,
                          message: <FormattedMessage id="please_enter_name" />,
                        },
                        {
                          pattern: "^[a-zA-Z ]+$",
                          message: <FormattedMessage id="only_alpha" />,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Full Name"
                        className="input-styling"
                      />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <Input
                        className="input-styling"
                        placeholder="Email"
                        type="email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Phone Number",
                        },
                        {
                          min: 10,
                          message: "Phone number must be greater 10 digits.",
                        },
                      ]}
                    >
                      <PhoneInput
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        countryCodeEditable={false}
                        country={"us"}
                        prefix="+"
                        inputStyle={{
                          width: "100%",
                          height: "55px",
                          backgroundColor: "white",
                          marginTop: "8px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please drop a message",
                        },
                      ]}
                    >
                      <div className="contact-box-content">
                        <Input.TextArea
                          style={{ width: "100%", height: "100%" }}
                          placeholder="Message"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className="contact-button"
                        htmlType="submit"
                        disabled={disable}
                      >
                        <span className="button-text">Contact</span>
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="agent-box">
                  <span className="agent-text">agent</span>
                  <div className="agent-details">
                    {agent?.agent_photo ? (
                      <img
                        src={property?.agent_photo}
                        alt=""
                        style={{
                          marginLeft: "20px",
                          width: "84px",
                          height: "84px",
                          borderRadius: "10px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Avatar
                        style={{ marginLeft: "20px" }}
                        shape="square"
                        size={84}
                        icon={<UserOutlined />}
                      />
                    )}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <span className="agent-name">{agent?.name}</span>
                      </div>
                      {agent?.agent_phone && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            height: "35px",
                          }}
                        >
                          <img
                            style={{ width: "14px", height: "14px" }}
                            src="/icons/phone1.png"
                            alt=""
                          />
                          <p className="listing-agent-details">
                            {agent?.agent_phone}
                          </p>
                        </div>
                      )}
                      {agent?.email && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            height: "35px",
                          }}
                        >
                          <img
                            style={{ width: "14px", height: "14px" }}
                            src="/icons/email1.png"
                            alt=""
                          />
                          <p className="listing-agent-details">
                            {agent?.email}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              height: "auto",
              marginTop: "40px",
              marginLeft: "130px",
            }}
          >
            <Col span={16}>
              <span className="description-section">DESCRIPTTION</span>
              <div
                dangerouslySetInnerHTML={{
                  __html: property?.description_en,
                }}
              ></div>
            </Col>
          </Row>
        </>
      )}
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        bodyStyle={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        okButtonProps={{ style: { backgroundColor: "#E5BE54" } }}
      >
        <p style={{ fontSize: "20px", width: "100%" }}>
          <FormattedMessage id="we_have_received" />
        </p>
      </Modal>
    </>
  );
}
