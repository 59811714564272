/** @format */

import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../styles/home.css";
import { Col, Dropdown, Row, Tooltip } from "antd";
import { HamMenu } from "./HamMenu";
import { routes_data } from "../../constants/StaticData";
import { FormattedMessage } from "react-intl";
import { FaRegUserCircle, FaSignOutAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import { Login } from "../login";
import { RxDashboard } from "react-icons/rx";
import { useQuery } from "@tanstack/react-query";
import { authAxios } from "../../utils/axiosMiddleware";
import { setProfileData } from "../../redux/reducers/UserReducer";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hamMenu, setHamMenu] = useState(false);
  const currentRoute = location.pathname;
  const mobileView = useMemo(() => window.innerWidth < 760, []);
  const handleHamMenu = () => {
    setHamMenu(!hamMenu);
  };
  const auth = JSON.parse(localStorage.getItem("userData"));
  const items = [
    {
      label: <FormattedMessage id="view_dashboard" />,
      key: "0",
      icon: <RxDashboard />,
      onClick: () => navigate("/user-dashboard"),
    },
    {
      label: <FormattedMessage id="Logout" />,
      key: "0",
      icon: <FaSignOutAlt />,
      onClick: () => {
        localStorage.removeItem("userData");
        window.location.reload();
      },
    },
  ];

  const {
    data: profileData,
    isError,
    isSuccess,
  } = useQuery({
    enabled: !!auth,
    queryKey: ["me"],
    queryFn: () => authAxios.get("/auth/me"),
    retry: false,
    select: (data) => data?.data,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setProfileData({
          profileName: profileData?.name,
          email: profileData?.email,
          roles: profileData?.["userRolePermissions"],
          permissions: profileData?.permissions,
          id: profileData?.id,
          userLanguage: profileData?.["languagePreference"],
        })
      );
    } else if (isError) {
      localStorage.removeItem("userData");
      window.location.href = "/";
    }
  }, [isSuccess, isError, profileData, dispatch]);

  return (
    <>
      {!currentRoute.includes("property-details") && (
        <>
          <Row className="home-header-container">
            <Col xs={10} md={4}>
              <div className="home-logo">
                <Link to="/">
                  {
                    <img
                      src={
                        currentRoute.includes("property-details")
                          ? "/icons/Logo Colored.svg"
                          : "/icons/Logo White.svg"
                      }
                      alt="logo"
                      className="logo-image"
                    />
                  }
                </Link>
              </div>
            </Col>
            <Col xs={10} lg={16}>
              {mobileView ? (
                <div className="mobileView">
                  <img
                    src="/icons/Hamburger.png"
                    className="hamburgerMenu"
                    alt="hamburgerMenuIcon"
                    onClick={() => setHamMenu(!hamMenu)}
                  />
                </div>
              ) : (
                <Row justify={"center"}>
                  {routes_data?.map((route) => {
                    return (
                      <Col
                        span={3}
                        className="header-alignment"
                        key={route?.name}
                      >
                        <div
                          onClick={() => navigate(route?.path)}
                          style={{ color: "white", cursor: "pointer" }}
                        >
                          <FormattedMessage id={route?.name} />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </Col>
            <Col lg={4} className="user-login-col">
              {!auth && (
                <>
                  <FaRegUserCircle className="user-icon" />
                  <span
                    className="user-icon-text"
                    onClick={() => dispatch(setLoginModalVisible(true))}
                  >
                    <FormattedMessage id="sign_in" />
                  </span>
                </>
              )}
              {auth && (
                <>
                  <div className="dashboard-icon">
                    <Tooltip title="Dashboard">
                      <RxDashboard
                        style={{ color: "white", fontSize: "20px" }}
                        onClick={() => navigate("/user-dashboard")}
                      />
                    </Tooltip>
                  </div>
                  <Dropdown
                    menu={{
                      items,
                    }}
                  >
                    <span className="user-icon-text">
                      <FaRegUserCircle className="user-login-icon" />
                      {profileData?.name}
                    </span>
                  </Dropdown>
                </>
              )}
            </Col>
          </Row>
          {hamMenu && <HamMenu handleHamMenu={handleHamMenu} />}
        </>
      )}
      <Login />
    </>
  );
};

export default Header;
