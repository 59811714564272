import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileData: {
    profileName: "",
    email: "",
    roles: [],
    permissions: [],
    id: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
  },
});

export const { setProfileData } = userSlice.actions;

export default userSlice.reducer;
