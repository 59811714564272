import React from "react";
import "../../styles/privacyPolicy.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
const PrivacyPolicy = () => {
  let navigate = useNavigate();
  return (
    <div>
      <div
        className="privacy-policy-button-container"
        onClick={() => navigate(-1)}
      >
        <div
          style={{
            display: "flex",
            width: "70px",
            paddingLeft: "130px",
            cursor: "pointer",
          }}
        >
          <ArrowLeftOutlined className="back-button-arrow" />
          <p style={{ margin: "auto" }} className="back-button-text">
            <FormattedMessage id="back" />
          </p>
        </div>
      </div>
      <div className="privacy-policy-heading-container">
        <p>
          <FormattedMessage id="privacy_policy" />
        </p>
      </div>
      <div className="privacy-policy-paragraph-container">
        <p>
          {" "}
          <FormattedMessage id="last_update" />
        </p>
      </div>

      <div className="pricavy-policy-text-container">
        <ol>
          <li>
            <FormattedMessage id="we_take" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="information_we" />
            <ul>
              <li style={{ listStyleType: "disc" }}>
                <FormattedMessage id="contact_info" />
              </li>
              <li style={{ listStyleType: "disc" }}>
                <FormattedMessage id="property_info" />
              </li>
              <li style={{ listStyleType: "disc" }}>
                <FormattedMessage id="usage_info" />
              </li>
            </ul>
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="we_may" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="we_take2" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="we_use" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="our_web" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="our_web1" />
          </li>
          <li style={{ paddingTop: "25px" }}>
            <FormattedMessage id="we_reserve" />
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
