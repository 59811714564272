import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import "../../styles/home.css";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { LoginUser, registerUser } from "../../api/auth";
import { FormattedMessage } from "react-intl";
export const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loginModalVisible } = useSelector((state) => state?.global);
  const [activeTab, setActiveTab] = useState("login");
  const { mutate: mutateRegister, isPending: registerLoading } = useMutation({
    mutationFn: (body) => registerUser(body),
  });
  const { mutate: mutateLogin, isPending: loginLoading } = useMutation({
    mutationFn: (body) => LoginUser(body),
  });

  const handleSubmit = useCallback(
    (values) => {
      let body = {
        email: values?.email,
        password: values?.password,
      };
      if (activeTab === "register") {
        body = {
          ...body,
          email: values?.email,
          name: values?.name,
          userType: "Customer",
          roles: [8],
        };
        mutateRegister(body, {
          onSuccess: () => {
            form.resetFields();
            setActiveTab("login");
            message?.success("Successfully registered");
          },
          onError: (err) => {
            message?.error(err?.data?.response?.message);
          },
        });
      } else {
        mutateLogin(body, {
          onSuccess: (res) => {
            form.resetFields();
            dispatch(setLoginModalVisible(false));
            localStorage.setItem("userData", JSON.stringify(res?.data));
            message?.success("Login Successfully");
            window.location.reload();
          },
          onError: (err) => {
            message?.error(err?.response?.data?.message);
          },
        });
      }
    },
    [activeTab, mutateLogin, mutateRegister, dispatch, form]
  );
  return (
    <Modal
      open={loginModalVisible}
      onCancel={() => dispatch(setLoginModalVisible(false))}
      footer={null}
      destroyOnClose={true}
    >
      <Row gutter={16} className="tab-row">
        <Col span={12}>
          <Button
            className={
              activeTab === "login"
                ? "tab-button tab-bg-selected"
                : "tab-button tab-bg-unselected"
            }
            onClick={() => {
              setActiveTab("login");
            }}
          >
            <FormattedMessage id="Login" />
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className={
              activeTab === "register"
                ? "tab-button tab-bg-selected"
                : "tab-button tab-bg-unselected"
            }
            onClick={() => setActiveTab("register")}
          >
            <FormattedMessage id="Register" />
          </Button>
        </Col>
      </Row>
      <div className="form-align">
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {activeTab === "register" && (
            <>
              <Form.Item
                name="name"
                label={<FormattedMessage id="Full Name" />}
                rules={[{ required: true, message: "please enter name" }]}
              >
                <Input
                  placeholder="Full Name"
                  prefix={<UserOutlined />}
                  className="input-style"
                />
              </Form.Item>

              <Form.Item
                name="username"
                label={<FormattedMessage id="username" />}
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="please enter username" />,
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  prefix={<UserOutlined />}
                  className="input-style"
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="email"
            label={<FormattedMessage id="Email" />}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="please enter email" />,
              },
              {
                type: "email",
                message: (
                  <FormattedMessage id="The input is not valid E-mail!" />
                ),
              },
            ]}
          >
            <Input
              placeholder="Email"
              prefix={<MailOutlined />}
              className="input-style"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={<FormattedMessage id="Password" />}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="please enter password" />,
              },
              {
                pattern: /[A-Z]/,
                message: "- Must have one uppercase letter (A-Z).",
              },
              {
                pattern: /[a-z]/,
                message: "- Must have one lowercase letter (a-z).",
              },
              {
                pattern: /\d/,
                message: "- Must have one number (0-9).",
              },
              {
                pattern: /[!@#$%^&*]/,
                message: "- Must have one special character (!@#$%^&*).",
              },
              {
                min: 8,
                message: "- Must be at least 8 characters long.",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              prefix={<LockOutlined />}
              className="input-style"
            />
          </Form.Item>
          {activeTab === "register" && (
            <Form.Item
              name="confirm"
              label={<FormattedMessage id="Confirm Password" />}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="Please confirm your password!" />
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={<LockOutlined />}
                className="input-style"
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              loading={!!registerLoading || !!loginLoading}
              htmlType="submit"
              className="tab-button tab-bg-selected"
            >
              <FormattedMessage id="submit" />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
