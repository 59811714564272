import React from "react";
import { FormattedMessage } from "react-intl";
import { bucket_base_url } from "../../constants/constant";
import { FaCalendarDays } from "react-icons/fa6";
import { truncateString } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";

export const BlogCard = ({ item }) => {
  const navigate = useNavigate();
  const extractDate = (dateString) => {
    const dateObject = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return dateObject.toLocaleDateString("en-US", options);
  };

  return (
    <div className="blog-card">
      <div className="blog-image">
        <img
          src={`${bucket_base_url}${item?.file[0]?.filePath}`}
          alt="blog"
          className="blog-image-2"
        />
      </div>
      <div className="blog-date">
        <FaCalendarDays />
        <span>{extractDate(item?.createdAt)}</span>
      </div>

      <div className="blog-title">{truncateString(item?.title)}</div>

      <div
        className="read-more"
        onClick={() =>
          navigate("/blog-details", {
            state: {
              id: item,
            },
          })
        }
      >
        <FormattedMessage id="Read More" />
      </div>
    </div>
  );
};
