import { Row } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";

const BlogDetails = () => {
  const { state } = useLocation();
  const decodeHtmlEntities = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Header />
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: "#272726",
            height: "81px",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: 1,
          }}
        ></div>
        <Row className="blogTitle">{state?.id?.title}</Row>

        <div
          className="blog-details"
          dangerouslySetInnerHTML={{
            __html: decodeHtmlEntities(state?.id?.content),
          }}
        />
      </div>
    </div>
  );
};

export default BlogDetails;
