import React from "react";
import "../../styles/home.css";
import { FeatureCard } from "../Feature/FeatureCard";
import { feature_data } from "../../constants/StaticData";
import { Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
const Feature = () => {
  return (
    <div className="home-feature-container">
      <div className="feature-heading-container">
        <p>
          <FormattedMessage id="our_features" />
        </p>
      </div>
      <div className="feature-text-container">
        <p>
          <FormattedMessage id="why_chose_us" />
        </p>
      </div>
      <Row className="home-feature-card" justify="center" align="center">
        {feature_data?.map((item) => {
          return (
            <Col
              xs={24}
              md={12}
              lg={6}
              xxl={5}
              className="card-col"
              key={item?.title}
            >
              <FeatureCard
                image={item?.image}
                title={item?.title}
                details={item?.details}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Feature;
