import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Pagination, Spin, Button, Select, Form } from "antd";
import { createIntervals, filterQueryParams } from "../../utils/Helper";
import "../../styles/properties.css";
import Header from "../Header";
import "../../styles/home.css";
import { PropertyCard } from "./PropertyCard";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPropertyList,
  getLikeProperty,
} from "../../api/Property/propertyApi";
import { LoadingOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { SkeletonCard } from "../common/Skeleton";

const { Option } = Select;

const Properties = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { listingQueryParams } = useSelector((state) => state?.property);
  const [stateData, setStateData] = useState({
    propertyType: "",
    location: "",
    priceRange: "",
    priceRangeArray: [],
  });
  const searchHandler = (formData) => {
    let priceValue;
    if (formData?.priceRange) {
      priceValue = formData?.priceRange?.split("-");
    }
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        skip: 0,
        currentPage: 1,
        property_type: formData?.property_type,
        community: formData?.community,
        minPrice: priceValue?.length > 0 ? priceValue[0] : 0,
        maxPrice: priceValue?.length > 0 ? priceValue[1] : 20000000,
      })
    );
  };

  const resetSearchHandler = useCallback(() => {
    dispatch(
      setListingQueryParams({
        ...listingQueryParams,
        skip: 0,
        currentPage: 1,
        property_type: null,
        community: null,
        minPrice: 0,
        maxPrice: 20000000,
      })
    );
  }, [dispatch, listingQueryParams]);

  const handleTableChange = useCallback(
    (current) => {
      dispatch(
        setListingQueryParams({
          ...listingQueryParams,
          currentPage: current,
          skip: (current - 1) * 6,
        })
      );
    },
    [dispatch, listingQueryParams]
  );
  const { take, skip, ...searchParams } = useMemo(() => {
    let params = { ...listingQueryParams };

    params = filterQueryParams({
      params,
      excludedParams: ["currentPage"],
    });

    return { ...params };
  }, [listingQueryParams]);

  const {
    data: propertyList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["Property", { take, skip, ...searchParams }],
    queryFn: ({ queryKey }) => {
      const [, params] = queryKey;
      return fetchPropertyList(params);
    },
    select: (res) => res?.data,
  });
  useEffect(() => {
    refetch();
  }, [refetch, listingQueryParams]);

  const locationOption = [
    {
      community: "Mohamed Bin Zayed City",
    },
    {
      community: "Al Reem Island",
    },
    {
      community: "Al Raha Beach",
    },
    {
      community: "Mussafah",
    },
    {
      community: "Sas Al Nakheel",
    },
    {
      community: "Al Bateen",
    },
    {
      community: "Masdar City",
    },
    {
      community: "Tourist Club Area",
    },
    {
      community: "Saadiyat Island",
    },
    {
      community: "Khalifa City",
    },
    {
      community: "Al Khalidiya",
    },
    {
      community: "Corniche Road",
    },
    {
      community: "Electra Street",
    },
    {
      community: "Al Mina",
    },
    {
      community: "The Marina",
    },
    {
      community: "Yas Island",
    },
    {
      community: "Al Hudayriat Island",
    },
    {
      community: "Rawdhat Abu Dhabi",
    },
    {
      community: "Capital Centre",
    },
    {
      community: "Danet Abu Dhabi",
    },
    {
      community: "Grand Mosque District",
    },
    {
      community: "Airport Road",
    },
    {
      community: "Hamdan Street",
    },
    {
      community: "Eastern Road",
    },
  ];
  const propertyType = [
    { key: "Apartment", value: "AP" },
    { key: "Duplex", value: "DX" },
    { key: " Penthouse", value: "PH" },
    { key: "Townhouse", value: "TH" },
    { key: "Villa", value: "VH" },
    { key: "Short term/ Hotel Apartment", value: "SH" },
  ];
  const handlePriceSelect = async () => {
    const array = createIntervals(5500, 10800000);
    setStateData((prev) => ({ ...prev, priceRangeArray: array }));
  };

  useEffect(() => {
    if (listingQueryParams?.property_type || listingQueryParams?.community) {
      form?.setFieldsValue({
        property_type: listingQueryParams?.property_type,
        community: listingQueryParams?.community,
      });
    } else {
      form.resetFields();
    }

    return () => form.resetFields();
  }, [listingQueryParams, form]);

  const auth = JSON.parse(localStorage.getItem("userData"));

  const {
    data: favorite,
    isLoading: favoriteLoading,
    refetch: likeRefetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  const MAX_CARDS = 6;
  return (
    <>
      <div className="properties-main-image-container">
        <Header />
        <div className="home-heading-container">
          <p>
            <FormattedMessage id="properties" />
          </p>
        </div>
        <div className="home-paragraph-container">
          <p>
            <FormattedMessage id="discover_perfect" />
          </p>
        </div>
        <div className="home-search-container">
          <Form form={form} onFinish={searchHandler}>
            <Row className="home-search-row">
              <Col xs={24} lg={5} className="col-alignment">
                <Form.Item name="property_type" className="margin-bottom">
                  <Select
                    placeholder={<FormattedMessage id="property_type" />}
                    className="property-type"
                  >
                    {propertyType?.map((option) => (
                      <Option key={option?.key} value={option?.value}>
                        {option?.key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={5} className="col-alignment">
                <Form.Item name="community" className="margin-bottom">
                  <Select
                    className="property-type"
                    placeholder={<FormattedMessage id="location" />}
                  >
                    {locationOption?.map((option) => (
                      <Option key={option?.community} value={option?.community}>
                        {option?.community}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={5} className="col-alignment">
                <Form.Item name="priceRange" className="margin-bottom">
                  <Select
                    placeholder={<FormattedMessage id="price_range" />}
                    onClick={handlePriceSelect}
                    options={stateData?.priceRangeArray}
                    className="property-type"
                    defaultValue={
                      `${listingQueryParams?.minPrice}-${listingQueryParams.maxPrice}` ===
                      "0-20000000"
                        ? null
                        : `${listingQueryParams?.minPrice}-${listingQueryParams.maxPrice}`
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={5} className="col-alignment">
                <Button
                  className=" search-button-container"
                  htmlType="submit"
                  // onClick={searchHandler}
                >
                  <FormattedMessage id="search" />
                </Button>
              </Col>
              <Col xs={24} lg={4} className="col-alignment">
                <Button
                  className="reset-button-container"
                  htmlType="submit"
                  onClick={resetSearchHandler}
                >
                  <FormattedMessage id="reset" />
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {isLoading ? (
        <div className="Loader-div">
          <Spin
            spinning={!!isLoading}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                  color: "#e5be54",
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <div style={{ marginTop: "70px" }}>
          <Row justify={"center"}>
            {propertyList?.records?.map((item) => {
              const likeArr = favorite?.filter(
                (like) => like?.propertyId === item?.id
              );
              return (
                <Col
                  xs={24}
                  md={12}
                  xl={8}
                  xxl={7}
                  className="col-alignment"
                  key={item?.id}
                >
                  <PropertyCard
                    item={item}
                    liked={likeArr}
                    refetch={likeRefetch}
                  />
                </Col>
              );
            })}
            {propertyList?.records?.length < MAX_CARDS &&
              propertyList?.records?.length !== 0 &&
              Array.from({
                length: MAX_CARDS - propertyList?.records?.length,
              }).map((_, index) => (
                <Col
                  xs={24}
                  md={12}
                  xl={8}
                  xxl={7}
                  className="col-alignment"
                  key={`skeleton-${index}`}
                >
                  <SkeletonCard />
                </Col>
              ))}

            {propertyList?.count === 0 && !isLoading && (
              <span className="no_data_found">
                <FormattedMessage id="no_data_found" />
              </span>
            )}
          </Row>
        </div>
      )}
      {propertyList?.records && (
        <Row justify={"center"} className="pagination-alignment">
          <Pagination
            current={listingQueryParams?.currentPage}
            total={propertyList?.count}
            pageSize={6}
            onChange={handleTableChange}
            showSizeChanger={false}
            size={"small"}
          />
        </Row>
      )}
    </>
  );
};

export default Properties;
