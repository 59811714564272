import axios from "axios";
import { base_url } from "../constants/constant";
export const Axios = axios.create({
  baseURL: base_url,
  headers: {
    "ngrok-skip-browser-warning": "69420",
  },
});
const auth = JSON.parse(localStorage.getItem("userData"));

export const authAxios = axios.create({
  baseURL: base_url,
  headers: {
    "ngrok-skip-browser-warning": "69420",
    Authorization: `Bearer ${auth?.access_token}`,
  },
});
