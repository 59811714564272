import React from "react";
import { Dashboard } from "../../components/UserDashboard/Dashboard";
import Header from "../../components/Header";

const UserDashboard = () => {
  return (
    <>
      <Header />
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: "#272726",
            height: "81px",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: 1,
          }}
        ></div>
        <Dashboard />
      </div>
    </>
  );
};

export default UserDashboard;
