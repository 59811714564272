import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginModalVisible: false,
};

const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setLoginModalVisible: (state, action) => {
      state.loginModalVisible = action.payload;
    },
  },
});

export const { setLoginModalVisible } = globalSlice.actions;
export default globalSlice.reducer;
