import React, { useRef } from "react";
import { Carousel, Rate, Row, Col } from "antd";
import "../../styles/testimonial.css";
import { useQuery } from "@tanstack/react-query";
import { Axios } from "../../utils/axiosMiddleware";
import { bucket_base_url } from "../../constants/constant";
export default function Testimonial() {
  const carouselRef = useRef();
  const handleLeftArrowClick = () => {
    carouselRef.current.prev();
  };
  const handleRightArrowClick = () => {
    carouselRef.current.next();
  };

  const { data: testimonials } = useQuery({
    queryKey: ["testimonial"],
    queryFn: () => {
      return Axios?.get("/testimonials");
    },
    select: (res) => res?.data?.records,
  });

  return (
    <div>
      {testimonials?.length > 0 && (
        <>
          <div className="testimonials-paragraph-container">
            <p className="testimonials-paragraph">Testimonials</p>
          </div>
          <div className="testimonials-heading-container">
            <p className="testimonials-heading">What clients says </p>
          </div>
          <div className="testimonials-detail-container">
            <p className="testimonials-detail">
              Our goal is at the heart of all that we do. We make our clients
              happiness our sole priority.{" "}
            </p>
          </div>
          <Row className="containerDiv">
            <Col lg={4} xl={6} md={4} sm={0} className="Arrow-align">
              <button className="left-arrow" onClick={handleLeftArrowClick}>
                <img src="/icons/leftarrow.png" alt="" />
              </button>
            </Col>
            <Col xl={12} lg={16} md={16} sm={24}>
              <Carousel
                dots={false}
                slidesToShow={1}
                autoplay
                ref={carouselRef}
              >
                {testimonials?.map((item) => {
                  return (
                    <div className="carosuel" key={item?.id}>
                      <div className="feedback-portion">
                        <div className="carosal-text">
                          <span>{item?.reviewText}</span>
                        </div>
                        <Rate value={item?.rating} className="rate" />
                      </div>
                      <div className="pic-portion">
                        <img
                          className="picture"
                          src={`${bucket_base_url}${item?.file?.filePath}`}
                          alt=""
                        />
                        <span className="name">{item.clientName}</span>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </Col>
            <Col lg={4} xl={6} md={4} sm={0} className="Arrow-align">
              <button onClick={handleRightArrowClick} className="left-arrow">
                <img src="/icons/arrow.png" alt="" />
              </button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
